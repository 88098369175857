import Back2Top from "./_Back2Top.js";
import CookieAbfrage from "./_CookieAbfrage.js";

import "lazysizes";

// import Navbar from './Navbar.js';
import "./_Links";
const footer_year = document.querySelector("#footer_year");
if (footer_year !== null) {
  var date = new Date();
  footer_year.innerText = date.getFullYear();
}

new Back2Top();
new CookieAbfrage();

localStorage.clear();
