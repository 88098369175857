// import "./_HomeGlider";

const dataLinks = document.querySelectorAll(".data-link");
dataLinks.forEach((link) => {
  link.addEventListener("click", () => {
    const ref = link.dataset.link;
    console.log(ref);
    if (ref) {
      window.location.href = ref;
    }
  });
});
