import Standard from "../../js/Libraries/Standard";
import jump from "jump.js";

export default class Back2Top extends Standard {
  constructor() {
    super();
    this.create();
    this.events();
  }

  create() {
    if (document.querySelector("#Back2Top .back_btn") === null) {
      const el = document.createElement("div");
      el.id = "Back2Top";
      el.innerHTML = `
                        <div class="back_btn">
                            <i class="fa fa-arrow-up"></i>
                        </div>
      `;
      this.wrapper = document.querySelector(".mobile-overflow-wrapper");
      if (this.wrapper !== null) {
        this.wrapper.insertAdjacentElement("afterbegin", el);
      } else {
        document.body.insertAdjacentElement("afterbegin", el);
      }

      this.btn = document.querySelector("#Back2Top .back_btn");
    } else {
      this.btn = document.querySelector("#Back2Top .back_btn");
    }
  }

  events() {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 600) {
        this.btn.style.display = "flex";
      } else {
        this.btn.style.display = "none";
      }
    });

    this.btn.addEventListener("click", () => {
      jump("body", {
        duration: (distance) => Math.abs(distance) / 2,
      });
    });
  }
}
