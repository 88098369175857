import jump from "jump.js";

const allGlobalJumpTo = document.querySelectorAll(".global-jump-to");

allGlobalJumpTo.forEach((x) => {
  x.addEventListener("click", (e) => {
    e.preventDefault();
    const target = x.dataset.target;
    if (document.querySelector(target) === null) {
      window.location.href = x.getAttribute("href");
    } else {
      jump(target, {
        duration: 1000,
        offset: 0,
      });
    }
  });
});
