import FullScreenSlides from "./FullScreenSlides";
import Navbar2 from "./_Navbar2";
import Video from "./_Video";
import HomeAnzeigen from "./_HomeAnzeigen";
import AllGlider from "./_AllGlider";

new FullScreenSlides("#FullScreenSlidesTop");
new FullScreenSlides("#FullScreenSlidesNews");
new Video();
new Navbar2();
new HomeAnzeigen();
new AllGlider();
