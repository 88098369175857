import Standard from "../../js/Libraries/Standard";
import Handlers from "../../js/Libraries/Handlers";
export default class HomeAnzeigen extends Standard {
  constructor() {
    super("#HomeAnzeigen");
    if (!super.exist()) return;
    this.objects = super.set(".objects-home-card", true);

    this.init();
  }

  //   /objekte?suche=
  async init() {
    this.events();
  }

  events() {
    this.objects.forEach((el) => {
      el.addEventListener("click", () => {
        let link = el.getAttribute("link");
        window.location.href = link;
      });
    });
  }
}
