import Standard from "../../js/Libraries/Standard";
import Handlers from "../../js/Libraries/Handlers";

export default class FullScreenSlides extends Standard {
  constructor(id) {
    super(id);

    if (!super.exist()) return;
    this.slidesContainer = super.set(".slides-container");
    this.create();
    this.nextBtn = super.set("#nextBtn");
    this.prevBtn = super.set("#prevBtn");
    this.dotsContainer = super.set(".z-dots-container");
    this.slides = super.set(".one-box", true);
    this.number = this.slides.length;
    this.current = 0;
    this.speed = 0.65;
    this.duration = 8000;
    this.hl = new Handlers();
    this.isAutomatic = false;

    if (this.number !== 0) {
      this.init();
    }
  }

  async init() {
    this.slides[0].classList.add("active");
    this.createDots();

    this.events();
    if (this.hl.elementInViewportAnyPart(this.container)) {
      this.isAutomatic = true;
    }
    this.automatic();
  }

  create() {
    this.slidesContainer.innerHTML += `
    
    <p class="handle-button" id="prevBtn">
          <i class="fas fa-chevron-left"></i>
    </p>

    <p class="handle-button" id="nextBtn">
        <i class="fas fa-chevron-right"></i>
    </p>

    <div class="z-dots-container"></div>
    
    
    `;
  }

  createDots() {
    for (var i = 0; i < this.number; i++) {
      this.dotsContainer.innerHTML +=
        '      <i class="z-dots fas fa-circle"></i>';
    }
    this.dots = super.set(".z-dots", true);
    this.dots[0].classList.add("active");
  }

  async automatic() {
    await this.hl.wait(this.duration);
    while (true) {
      if (this.isAutomatic) {
        this.next();
      }
      await this.hl.wait(this.duration);
    }
  }

  next() {
    this.slides[
      this.current
    ].style.animation = `inactive-left ${this.speed}s ease forwards`;
    this.slides[this.current].classList = "one-box";
    if (this.current + 1 < this.number) {
      this.slides[
        this.current + 1
      ].style.animation = `active-right ${this.speed}s ease forwards`;
      this.slides[this.current + 1].classList = "one-box active";
      this.current += 1;
    } else {
      this.slides[0].style.animation = `active-right ${this.speed}s ease forwards`;
      this.slides[0].classList = "one-box active";
      this.current = 0;
    }
    this.setActiveDot();
  }
  prev() {
    this.slides[
      this.current
    ].style.animation = `inactive-right ${this.speed}s ease forwards`;
    this.slides[this.current].classList = "one-box";
    if (this.current - 1 >= 0) {
      this.slides[
        this.current - 1
      ].style.animation = `active-left ${this.speed}s ease forwards`;
      this.slides[this.current - 1].classList = "one-box active";
      this.current -= 1;
    } else {
      this.slides[
        this.number - 1
      ].style.animation = `active-left ${this.speed}s ease forwards`;
      this.slides[this.number - 1].classList = "one-box active";
      this.current = this.number - 1;
    }
    this.setActiveDot();
  }
  setActiveDot() {
    for (var i = 0; i < this.number; i++) {
      this.dots[i].classList.remove("active");
    }

    this.dots[this.current].classList.add("active");
  }
  events() {
    this.nextBtn.addEventListener("click", () => {
      this.next();
      this.isAutomatic = false;
    });
    this.prevBtn.addEventListener("click", () => {
      this.prev();
      this.isAutomatic = false;
    });
    document.addEventListener("scroll", () => {
      if (this.hl.elementInViewportAnyPart(this.container)) {
        this.isAutomatic = true;
      } else {
        this.isAutomatic = false;
      }
    });
  }
}
