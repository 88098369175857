const link_to = document.querySelectorAll(".link_to");
link_to.forEach((el) => {
  el.addEventListener("click", () => {
    let link = el.getAttribute("link_to");
    if (link !== null) {
      window.location.href = link;
    }
  });
});

const link_to_blanket = document.querySelectorAll(".link_to_blanket");
link_to_blanket.forEach((el) => {
  el.addEventListener("click", () => {
    let link = el.getAttribute("link_to");
    if (link !== null) {
      window.open(link, "_blank");
    }
  });
});
