import "../../js/Gadgets/_glider_extern";

import Standard from "../../js/Libraries/Standard";
import ZachiHttp from "../../js/Libraries/ZachiHttp";
import Handlers from "../../js/Libraries/Handlers";

export default class AllGlider extends Standard {
  constructor() {
    super("body");
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.init();
  }

  async init() {
    await this.gliderCategories();
    await this.gliderAnzeigen();
  }
  async gliderAnzeigen() {
    const decks = document.querySelectorAll(".gliderAnzeigen");
    decks.forEach(async (deck) => {
      const glider = deck.querySelector(`.glider`);
      glider.style.display = "none";
      await this.hl.wait(40);
      glider.style.display = "block";
      const gliderPrev = deck.querySelector(`.glider-prev`);
      const gliderNext = deck.querySelector(`.glider-next`);
      const gliderDots = deck.querySelector(`.dots`);

      if (
        !(
          glider !== null &&
          gliderPrev !== null &&
          gliderNext !== null &&
          gliderDots !== null
        )
      ) {
      }

      const arrows = {
        prev: gliderPrev,
        next: gliderNext,
      };
      const responsive = [
        {
          // If Screen Size More than 768px
          breakpoint: 320,
          settings: {
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 750,
          settings: {
            slidesToShow: 1.5,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 920,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
      ];

      new Glider(glider, {
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        dots: gliderDots,
        responsive: responsive,
      });
    });
  }

  async gliderCategories() {
    const decks = document.querySelectorAll(".gliderCategories");
    decks.forEach(async (deck) => {
      const glider = deck.querySelector(`.glider`);
      glider.style.display = "none";
      await this.hl.wait(40);
      glider.style.display = "block";
      const gliderPrev = deck.querySelector(`.glider-prev`);
      const gliderNext = deck.querySelector(`.glider-next`);
      const gliderDots = deck.querySelector(`.dots`);

      if (
        !(
          glider !== null &&
          gliderPrev !== null &&
          gliderNext !== null &&
          gliderDots !== null
        )
      ) {
      }

      const arrows = {
        prev: gliderPrev,
        next: gliderNext,
      };
      const responsive = [
        {
          // If Screen Size More than 768px
          breakpoint: 320,
          settings: {
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 640,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 750,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 920,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
      ];

      new Glider(glider, {
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        dots: gliderDots,
        responsive: responsive,
      });
    });
  }
}
