import Standard from "../../js/Libraries/Standard";
import Handlers from "../../js/Libraries/Handlers";
import { COOKIE_STATUS } from "../../CONSTANTS";
export default class CookieAbfrage extends Standard {
  constructor() {
    super("#CookieAbfrage");
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.cookie_status = sessionStorage.getItem(COOKIE_STATUS);
    if (this.cookie_status === null) {
      this.cookie_status = 0;
    } else {
      this.cookie_status = parseInt(this.cookie_status);
    }
    this.btn = super.set("button");
    this.init();
  }

  async init() {
    if (this.cookie_status !== 1) {
      this.container.classList.add("active");
      await this.hl.wait(500);
      this.container.classList.add("show");
    }
    this.events();
  }

  events() {
    this.btn.addEventListener("click", async () => {
      this.cookie_status = sessionStorage.setItem(COOKIE_STATUS, 1);
      this.container.classList.remove("active");
      await this.hl.wait(200);
      this.container.classList.remove("show");
    });
  }
}
