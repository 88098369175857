export default class Handlers {
  constructor() {
    this.createLoader();
    this.createMessage();
  }
  createLoader() {
    if (document.querySelector("#loader-in-handlers") === null) {
      const loader = document.createElement("div");
      loader.id = "loader-in-handlers";
      loader.innerHTML = `
                  <div class='hollowLoader'>
                      <div class='largeBox'></div>
                      <div class='smallBox'></div>
                  </div>
      `;

      document.body.insertAdjacentElement("afterbegin", loader);
      this.loader = document.querySelector("#loader-in-handlers");
    } else {
      this.loader = document.querySelector("#loader-in-handlers");
    }
  }

  createMessage() {
    if (document.querySelector("#message-in-handlers") === null) {
      const box = document.createElement("div");
      box.id = "message-in-handlers";
      box.innerHTML = `
                    <div class="content">
                        <div class="close"><i class="far fa-times-circle"></i></div>
                         <p class="pt-4"></p>
                    </div>`;

      document.body.insertAdjacentElement("afterbegin", box);
      this.messageBox = document.querySelector("#message-in-handlers");
    } else {
      this.messageBox = document.querySelector("#message-in-handlers");
    }
  }
  toggle() {
    this.loader.classList.toggle("active");
  }
  removeToggle() {
    this.loader.classList.remove("active");
  }

  message(
    msg = "Es gibt gereade leider technische Probleme mit unserer Webseite!"
  ) {
    this.messageBox.classList.add("active");
    this.messageBox.querySelector("p").innerHTML = msg;
    function closeBox(self) {
      self.messageBox.classList.remove("active");
    }
    function outsideClick(e, self) {
      if (e.target == self.messageBox) {
        self.messageBox.classList.remove("active");
      }
    }

    this.messageBox
      .querySelector(".close")
      .addEventListener("click", () => closeBox(this));
    document.addEventListener("click", (e) => outsideClick(e, this));
  }

  elementInViewportAnyPart(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top < window.pageYOffset + window.innerHeight &&
      left < window.pageXOffset + window.innerWidth &&
      top + height > window.pageYOffset &&
      left + width > window.pageXOffset
    );
  }

  widthOfScreen() {
    å;
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }
  wait(time = 2000) {
    return new Promise((resolve, reject) => {
      // if (false) {
      //   reject('Reject');
      // }
      setTimeout(() => {
        resolve();
      }, time);
    });
  }
}
