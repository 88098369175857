import Standard from "../../js/Libraries/Standard";
import Handlers from "../../js/Libraries/Handlers";
import { USER_IS_LOGGED } from "../../CONSTANTS";
export default class Navbar2 extends Standard {
  constructor() {
    super("#navbar2");
    if (!super.exist()) return;

    this.inputSuche = super.set("#inputSuche");
    this.detailSearch = super.set("#detailSearch");
    this.dropdownSuche = super.set("#dropdownSuche");
    this.burger = super.set(".z-burger");
    this.pages = super.set(".z-nav-pages");
    this.navLinks = super.set(".z-nav-page", true);
    this.speed = 0.65;
    this.isActive = false;
    this.hl = new Handlers();
    this.init();
  }

  //   /objekte?suche=
  async init() {
    this.events();
    await this.read();
  }

  async read() {
    const isLogged = JSON.parse(sessionStorage.getItem(USER_IS_LOGGED));
    const anmeldeBtnContainer = super.set("#anmeldeBtnContainer");
    const registerBtn = super.set("#registerBtn");
    if (anmeldeBtnContainer === null || registerBtn === null) {
      return;
    }

    if (isLogged === true) {
      anmeldeBtnContainer.style.display = "none";
      registerBtn.innerText = "Admin";
      registerBtn.href = "/dashboard";
    }
  }
  onEnterSearch(e, isClick = false) {
    if (e.key === "Enter" || isClick === true) {
      window.location.href = "/objekte?suche=" + this.inputSuche.value;
    }
  }
  animate(onScroll = false) {
    if (onScroll === true && this.isActive === false) {
      return;
    }
    if (this.isActive) {
      this.pages.style.animation = `navSlideOut ${this.speed}s ease backwards`;
      this.isActive = false;
    } else {
      this.pages.style.animation = `navSlideIn ${this.speed}s ease forwards`;
      this.isActive = true;
    }
    this.burger.classList.toggle("z-toggle");
    this.navLinks.forEach((link, index) => {
      var speed_pro = index / 8 + this.speed - 0.35;
      if (link.style.animation) {
        link.style.animation = "";
      } else {
        link.style.animation = `navLinkFade 0.5s ease forwards ${speed_pro}s`;
      }
    });
  }
  events() {
    this.burger.addEventListener("click", () => {
      this.animate();
    });
    this.inputSuche.addEventListener("focus", () => {
      this.dropdownSuche.classList.add("active");
    });
    this.inputSuche.addEventListener("keydown", (e) => {
      this.onEnterSearch(e, false);
    });

    this.inputSuche.addEventListener("blur", async () => {
      await this.hl.wait(300);
      this.dropdownSuche.classList.remove("active");
    });
    this.detailSearch.addEventListener("click", (e) => {
      this.onEnterSearch(e, true);
    });
    window.addEventListener("resize", () => {
      this.animate(true);
    });
  }
}
